.quick ul {
    text-align: start;
    color: #cfcfcf;
}

.quick ul li {
    list-style: none;
    padding: 1rem;
}

.quick ul li,
.quick ul li a {
    text-decoration: none;
    color: #cfcfcf;
    font-size: 1.2rem;
}

.page-footer {
    font-size: 12px;
    color: #f5960e;
}

ul {
    padding-left: 0;
}