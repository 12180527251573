.fa-arrow-left:hover,
.fa-arrow-right:hover {
    cursor: pointer;
}

.my-info h2,
.my-info-second-col h2 {
    padding-top: 1rem;
}

.my-info-second-col h2 {
    color: #F5960E;
}

.card-body3 {
    padding: 10rem 0rem;
    border-radius: 8px;
}

.fa-pen-to-square {
    font-size: 1.7rem;
    margin-right: 5px;
}