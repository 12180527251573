.download {
    background-color: #F5960E;
    color: white;
    /* border: 1px solid black; */
    border-radius: 8px;
    cursor: pointer;
}

.download .fa-solid {
    font-size: 1.7rem;
}