* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.nav-bar {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 20px 80px;
    background-color: black;
    ;
}

#navbar {
    display: flex;
    align-items: center;
    justify-content: center;
}

.nav-links {
    list-style: none;
    padding: 20px;
    position: relative;
}

.nav-links a {
    text-decoration: none;
    font-size: 1.8rem;
    font-weight: 500;
    color: white;
    transition: 0.3s ease-in-out;
    position: relative;
    /* Add this line */
}

.nav-links a:hover {
    color: #f6a70f;
}

.nav-links a::before {
    /* Change here */
    content: "";
    width: 0%;
    /* Change here */
    height: 2px;
    /* background-color: #d72323; */
    position: absolute;
    bottom: -4px;
    left: 50%;
    /* Change here */
    transition: width 0.3s ease;
    /* Change here */
    transform: translateX(-50%);
    /* Change here */
}

.nav-links a:hover::before {
    /* Change here */
    width: 100%;
    /* Change here */
}

#mobile {
    display: none;
}

#mobile i {
    align-items: center;
}

.our-product:hover,
.header__sub {
    display: block;
}

/* hobver and Click effect  */
#our-product .header-sub-menu {
    visibility: visible;
    margin-top: 20px;
    opacity: 1;
}



.header-sub-menu li {
    font-size: 1.5rem;
    display: block;
    padding: 10px 0;
    font-weight: 100;
}

@media screen and (max-width: 820px) {
    #navbar {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        position: absolute;
        top: 107px;
        left: -300px;
        width: 238px;
        height: 100vh;
        background-color: black;
        padding: 27px 0 0 30px;
        transition: .3s ease-in-out;
        z-index: 8;
    }

    .header-sub-menu li {
        font-size: 1.5rem;
        display: block;
        padding: 7px 0px 0px 0px;
        font-weight: 500;
    }

    #our-product .header-sub-menu {
        margin-top: -9px;
    }

    .nav-links {
        margin-bottom: -5px;
    }

    img {
        vertical-align: bottom;
    }

    .nav-bar {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        padding: 20px 40px;
    }

    #navbar.active {
        left: 0;
    }

    .nav-links li {
        margin-bottom: 5px;
    }

    #mobile {
        display: block;
    }

    #mobile i {
        font-size: 24px;
        cursor: pointer;
        color: white;
    }
}

.fa-user {
    font-size: 3rem;
    cursor: pointer;
}

.user-profile-content {
    font-size: 1.5rem;
    /* margin-top: 4rem; */

}

.user-profile-content ul {
    list-style: none;
}

.user-profile-content ul li a {
    color: #f6a70f;
    text-decoration: none;
}

.fa-gear,
.fa-bell {
    font-size: 1.7rem;
}

.modal-dialog1 {
    position: absolute;
    width: auto;
    right: 0;
    margin: .5rem;
    pointer-events: none;
}