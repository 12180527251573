.fa-arrow-right {
    font-size: 1.7rem;
}

.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked+.slider {
    background-color: #F5960E;
}

input:focus+.slider {
    box-shadow: 0 0 1px #F5960E;
}

input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

input[type=radio] {
    border: 0px;
    width: 50%;
    height: 1em;
    /* background-color: #F5960E; */
    accent-color: #F5960E;
    border: 3px solid #F5960E;
}

input[type="radio"] {
    /* Hide the default radio button */
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    /* Define the size of the radio button */
    width: 20px;
    height: 20px;
    /* Border style */
    border: 2px solid #ccc;
    /* Default border color */
    border-radius: 50%;
    /* Make it round */
    /* Add margin or padding if needed */
}

input[type="radio"]:checked {
    border-color: #ccc;
    background-color: #F5960E;
    /* Change border color when checked */
}