.card-body2 {
  flex: 1 1 auto;
  padding: 2.2rem 2rem;
  /* border-radius: 10px; */
}

.fa-plus {
  font-size: large;
}

.new-card:hover {
  cursor: pointer;
}

button {
  border-radius: 5px;
}

input {
  outline: none;
  border: 1px solid;
  border-radius: 5px;
  font-size: 1.5rem;
}

input::placeholder {
  font-size: 1.5rem;
  margin-top: 15px;
  vertical-align: middle;
}

.btn-close {
  font-size: 1.8rem;
  color: black;
  font-weight: "700";
}
