.login-background {
  background-image: url("/src/dashboard/login-background-img.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right;
  min-height: 90vh;
}

/* Add this CSS to your login.css file */

.pet-info {
  width: 100%;
  padding: 5px;
  font-size: 16px;
  /* border: 1px solid #ccc; */
  border-radius: 5px;
  margin-bottom: 20px;
  box-sizing: border-box;
  /* Ensure padding and border are included in the element's total width and height */
}

/* Style for the PhoneInput component */
/* You may need to adjust these styles to match your desired button style */

.react-phone-number-input {
  width: 100%;
}

/* Optionally, you can style the PhoneInput's input element */
.react-phone-number-input__input input {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
}

.PhoneInputInput {
  flex: 1 1;
  outline: none;
  /* text-align: center; */
  min-width: 0;
  padding: 10px;
}
