.cats {
  background-image: url(/public/assets/images/dashboard/dash-img-cat.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right;
}

.dogs {
  background-image: url(/public/assets/images/dashboard/dash-img-dog.jpeg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right;
}

.fa-solid {
  font-size: 3rem;
}

.card {
  border-radius: 8px;
}

.card-body {
  flex: 1 1 auto;
  padding: 6rem 4rem;
  /* border-radius: 10px; */
}

.card-body1 {
  flex: 1 1 auto;
  padding: 3.5rem 4rem;
  /* border-radius: 10px; */
}

.card-body2 {
  flex: 1 1 auto;
  padding: 2.2rem 4rem;
  /* border-radius: 10px; */
}

.dashborad-cnt {
  margin-bottom: 7rem !important;
}

.activeBody {
  background-color: black;
}

.activeFont {
  color: white;
}

.inactiveBody {
  background: transparent;
  filter: blur(2px);
}

/* mapbox */
.map-container {
  height: 400px;
}

.sidebar {
  background-color: rgb(35 55 75 / 90%);
  color: #fff;
  padding: 6px 12px;
  font-family: monospace;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  margin: 12px;
  border-radius: 4px;
}
